* {
  margin: 0px;
  padding: 0px;
}

.container {
  width: 98.5%;
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  height: fit-content;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactUs {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  background-color: #e46c0a;
  color: white;
  font-size: 14px;
  margin-right: 10px;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 300;
  padding: 1rem 2rem;
  transform: scale(0.95, 1.1);
}

.contactUs:hover {
  cursor: pointer;
  background-color: white;
  color: #e46c0a;
  transition: 0.3s;
}

.contactUs:active {
  transform: scale(0.9);
}

.contactUs:disabled {
  background-color: #ebebeb;
  color: white;
  border: 1px solid white;
}

.logo {
  width: 130px;
  height: 110px;
}

.progressBarContainer {
  border-radius: 5px;
  height: 10px;
}

.secondContainer {
  display: flex;
  flex-direction: column;
}

.textContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.textContainer2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: min-content;
}

.text1 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #3f2406;
}

.text2 {
  font-weight: bold;
  font-size: 50px;
  line-height: 70px;
  color: #e46c0a;
}

.text3 {
  font-size: 15;
  line-height: 70px;
  color: #3f2406;
}

.reallyBigTitle {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: bold;
  padding-top: 3px;
  line-height: 2.5rem;
  padding-top: 10px;
  padding-bottom: -5px;
  color: #3f2406;
}

.oneRemText {
  font-size: 0.938rem;
  padding-top: -5px;
  font-weight: 500;
  color: #3f2406;
}

.bigText {
  font-weight: bold;
  color: #e46c0a;
}

.semiboldText {
  font-weight: 600;
  color: #3f2406;
}

.orangeText {
  font-weight: 500;
}

.semiboldOrangeText {
  font-weight: 500;
  color: #e46c0a;
}

.graphContainer {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  min-height: 180px;
  max-height: 180px;
  width: 100%;
}

.chart {
  flex: 1;
}

.input {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  color: #e46c0a;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}

.continueButton {
  background-color: #e46c0a;
  border: 1px solid #e46c0a;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.continueButton:active {
  transform: scale(0.9);
}

.priceContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  flex: 1;
}

.priceText {
  font-size: 1.25rem;
  text-align: center;
  color: #3f2406;
  text-transform: capitalize;
}

.priceText2 {
  font-size: 1.563rem;
  text-align: center;
  color: #3f2406;
  text-transform: capitalize;
}

.animatedNumber {
  font-size: 10px;
}

@media screen and (min-width: 576px) {
  .logo {
    width: 150px;
    height: 120px;
  }

  .progressBarContainer {
    margin-bottom: 15px;
    margin-top: 15px;
    background-color: #ebebeb;
    height: 15px;
  }

  .reallyBigTitle {
    margin-bottom: 30px;
  }

  .semiboldText {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .bigText {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .orangeText {
    font-size: 10px;
    margin-bottom: 20px;
  }

  .semiboldOrangeText {
    font-size: 13px;
  }

  .buttonContainer {
    margin-top: 20px;
  }

  .input {
    min-width: 140px;
    max-width: 140px;
    max-height: 40px;
    min-height: 40px;
    border-width: 3px;
    margin-right: 30px;
    background-image: url('../images/pin.png');
    background-repeat: no-repeat;
    background-position: 4% 50%;
    padding-left: 40px;
    font-size: 14px;
  }

  .continueButton {
    font-size: 12px;
    min-width: 150px;
  }

  .priceText {
    text-transform: capitalize;
    margin: 0px auto;
    color: #3f2406;
  }
}
@media screen and (min-width: 768px) {
  .logo {
    width: 150px;
    height: 120px;
  }

  .progressBarContainer {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #ebebeb;
  }

  .reallyBigTitle {
    margin-bottom: 30px;
  }

  .semiboldText {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .bigText {
    font-size: 38px;
    margin-bottom: 10px;
  }

  .orangeText {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .semiboldOrangeText {
    font-size: 14px;
  }

  .buttonContainer {
    margin-top: 20px;
  }

  .input {
    min-width: 180px;
    max-width: 180px;
    max-height: 40px;
    min-height: 40px;
    border-width: 3px;
    margin-right: 30px;
    background-image: url('../images/pin.png');
    background-repeat: no-repeat;
    background-position: 4% 50%;
    padding-left: 40px;
    font-size: 14px;
  }

  .continueButton {
    font-size: 14px;
    min-width: 180px;
  }

  .priceText {
    margin: 0px auto;
  }
}
@media screen and (min-width: 992px) {
  .oneRemText {
    margin-top: -1rem;
  }

  .graphContainer {
    height: 200px;
  }

  .secondContainer {
    flex-direction: row;
  }

  .logo {
    width: 150px;
    height: 120px;
  }

  .progressBarContainer {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #ebebeb;
  }

  .reallyBigTitle {
    margin-bottom: 30px;
  }

  .semiboldText {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .bigText {
    font-size: 42px;
    margin-bottom: 10px;
  }

  .orangeText {
    font-size: 13px;
    margin-bottom: 30px;
  }

  .semiboldOrangeText {
    font-size: 16px;
  }

  .buttonContainer {
    margin-top: 20px;
  }

  .input {
    min-width: 200px;
    max-width: 200px;
    max-height: 45px;
    min-height: 45px;
    border-width: 3px;
    margin-right: 30px;
    background-image: url('../images/pin.png');
    background-repeat: no-repeat;
    background-position: 4% 50%;
    padding-left: 40px;
    font-size: 16px;
  }

  .continueButton {
    font-size: 16px;
    min-width: 200px;
  }

  .priceContainer {
    flex: 40%;
  }

  .priceText {
    margin: 0px auto;
  }
}
@media screen and (min-width: 1200px) {
  .logo {
    width: 150px;
    height: 120px;
  }

  .progressBarContainer {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #ebebeb;
  }

  .reallyBigTitle {
    margin-bottom: 30px;
  }

  .semiboldText {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .bigText {
    font-size: 46px;
    margin-bottom: 10px;
  }

  .orangeText {
    font-size: 14px;
    margin-bottom: 50px;
  }

  .semiboldOrangeText {
    font-size: 18px;
  }

  .buttonContainer {
    margin-top: 20px;
  }

  .input {
    min-width: 220px;
    max-width: 220px;
    max-height: 45px;
    min-height: 45px;
    border-width: 3px;
    margin-right: 30px;
    background-image: url('../images/pin.png');
    background-repeat: no-repeat;
    background-position: 4% 50%;
    padding-left: 40px;
    font-size: 18px;
  }

  .continueButton {
    font-size: 18px;
    min-width: 220px;
  }

  .priceText {
    margin: 0px auto;
  }
}

@media screen and (min-width: 1400px) {
  .logo {
    width: 150px;
    height: 120px;
  }

  .progressBarContainer {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #ebebeb;
  }

  .reallyBigTitle {
    margin-bottom: 30px;
  }

  .semiboldText {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .bigText {
    font-size: 50px;
    margin-bottom: 10px;
  }

  .orangeText {
    font-size: 15px;
    margin-bottom: 50px;
  }

  .semiboldOrangeText {
    font-size: 20px;
  }

  .buttonContainer {
    margin-top: 20px;
  }

  .input {
    min-width: 250px;
    max-width: 250px;
    max-height: 50px;
    min-height: 50px;
    border-width: 3px;
    margin-right: 30px;
    background-image: url('../images/pin.png');
    background-repeat: no-repeat;
    background-position: 3% 50%;
    padding-left: 40px;
    font-size: 20px;
  }

  .continueButton {
    font-size: 20px;
    min-width: 250px;
  }

  .priceText {
    margin: 0px auto;
  }
}
