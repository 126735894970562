* {
  margin: 0px;
  padding: 0px;
  -webkit-text-size-adjust: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 320px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 150px;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60vw;
  background-image: url('/src/images/PriceBackground.png');
  background-repeat: no-repeat;
  background-size: 60vw 150px;
  flex-basis: 100%;
}

.cheapContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.avgContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.maxContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.priceText {
  font-size: 0.9rem;
  text-align: center;
  color: #3f2406;
}

.priceText2 {
  font-size: 1rem;
  text-align: center;
  color: #3f2406;
}

.bigText {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: bold;
  color: #e46c0a;
}

.averageText {
  font-size: 1.25rem;
  font-weight: 600;
  color: #3f2406;
}

.smallText {
  font-size: 0.938rem;
  font-weight: 500;
  color: #3f2406;
}

@media screen and (min-width: 768px) {
  * {
    margin: 0px;
    padding: 0px;
    -webkit-text-size-adjust: 100%;
  }

  .container {
    display: flex;
    flex-direction: row;
    height: 140px;
    justify-content: space-between;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 40%;
    margin-bottom: -1.1rem;
  }

  .imgContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30vw;
    height: 140px;
    background-image: url('/src/images/PriceBackground.png');
    background-repeat: no-repeat;
    background-size: 30vw 140px;
    flex-basis: 30%;
  }

  .cheapContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .avgContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .maxContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .priceText {
    font-size: 1rem;
    text-align: center;
    color: #3f2406;
  }

  .priceText2 {
    font-size: 1.2rem;
    text-align: center;
    color: #3f2406;
  }

  .bigText {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: bold;
    color: #e46c0a;
    padding-top: 3px;
  }

  .bigTextBlack {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: bold;
    color: #3f2406;
    margin: 0;
    padding-left: 0px;
    text-align: left;
  }

  .averageText {
    font-size: 1.25rem;
    font-weight: 600;
    color: #3f2406;
  }

  .smallText {
    font-size: 0.938rem;
    font-weight: 500;
    color: #3f2406;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 992px) {
  * {
    margin: 0px;
    padding: 0px;
    -webkit-text-size-adjust: 100%;
  }

  .container {
    display: flex;
    flex-direction: row;
    height: 140px;
    justify-content: space-between;
    align-content: center;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 60%;
    margin-bottom: -1.1rem;
  }

  .imgContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30vw;
    height: 140px;
    background-image: url('/src/images/PriceBackground.png');
    background-repeat: no-repeat;
    background-size: 30vw 140px;
    flex-basis: 30%;
  }

  .cheapContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .avgContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .maxContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .priceText {
    font-size: 1.25rem;
    text-align: center;
    color: #3f2406;
  }

  .priceText2 {
    font-size: 1.563rem;
    text-align: center;
    color: #3f2406;
  }

  .bigText {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: bold;
    color: #e46c0a;
    padding-top: 3px;
  }

  .bigTextBlack {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: bold;
    color: #3f2406;
    margin: 0;
    padding-left: 0px;
    text-align: left;
  }

  .averageText {
    font-size: 1.25rem;
    font-weight: 600;
    color: #3f2406;
  }

  .smallText {
    font-size: 0.938rem;
    font-weight: 500;
    color: #3f2406;
    margin-bottom: 1rem;
  }
}
