* {
  margin: 0px;
  padding: 0px;
}

textarea:focus,
input:focus {
  outline: none !important;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.questionContainerHidden {
  margin-bottom: 1rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0s, opacity 0s;
  transform: scale(0.9);
}

.formInput {
  margin-bottom: 10px;
  height: 3rem;
  width: 100%;
}

.questionContainerVisible {
  margin-bottom: 1rem;
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 400ms, transform 400ms;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 3%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 60px 30px 25px 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  z-index: 100;
}

.modalSmall {
  position: absolute;
  left: 0;
  right: 0;
  top: 3%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 60px 30px 25px 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  z-index: 100;
}

.modalSmallText {
  color: #e46c0a;
  font-size: 1.5rem;
  font-weight: 600;
}

.errorMessage {
  font-size: 0.75rem;
  line-height: 0.75rem;
  align-self: flex-start;
  color: #dc3232;
  margin-top: 5px;
}

label {
  color: #3f2406;
}

.checkboxText {
  line-height: 1.3rem;
  font-size: 0.9rem;
  color: #3f2406;
  margin-left: 8px;
}

.checkbox {
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid #aeaeae;
  border-radius: 8px;
}

.checkbox:hover {
  cursor: pointer;
}

.checkboxActive {
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid #e46c0a;
  border-radius: 8px;
  background-color: #e46c0a;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0;
  text-align: center;
}

.checkboxActive:hover {
  cursor: pointer;
}

.check {
  fill: white;
  margin: 0 auto;
}

.X {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #e46c0a;
  font-weight: bold;
  width: 15px;
  height: 15px;
}
.X:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.modalButton {
  transition: 0.3s;
  border: 1px solid white;
  border-radius: 5px;
  text-align: center;
  color: white;
  background-color: #e46c0a;
  min-height: 35px;
  max-height: 35px;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: capitalize;
  padding: 0 10px;
}

.modalInput {
  border-radius: 8px;
  width: 100%;
  border: 1px solid #aeaeae;
  padding-left: 5px;
  height: 34px;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Source Sans Pro', sans-serif;
}

.modalInput:focus {
  border: 1px solid #e46c0a;
}

.modalButton:hover {
  cursor: pointer;
  border-color: #e46c0a;
  color: #e46c0a;
  background-color: white;
}
.modalButton:active {
  transform: scale(0.9);
}

.newContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(2 * (1.25rem + 85px));
  margin-bottom: auto;
}

.newContainer2 {
  display: flex;
  flex-direction: row;
}

.newContainerButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: auto;
}

h2 {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #e46c0a;
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-size: 40px;
}

h2 span {
  background: #fff;
  padding: 0 10px;
  color: #e46c0a;
  font-weight: 600;
}

.highInput {
  border: 1px solid #aeaeae;
  border-radius: 5px;
  text-align: center;
  min-height: 80px;
  max-height: 80px;
  font-size: 1rem;
  width: 102%;
  margin: 0px;
  font-weight: 400;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  padding-left: 5px;
}

.highInput:focus {
  border: 1px solid #e46c0a;
}

.bigContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.container {
  width: 98.5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden !important;
}

.input {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  text-align: center;
  color: #e46c0a;
  min-height: 35px;
  max-height: 35px;
  font-size: 16px;
  max-width: 120px;
  min-width: 120px;
}

.dropUp {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  text-align: center;
  color: #e46c0a;
  min-height: 35px;
  max-height: 35px;
  min-width: 235px;
  max-width: 235px;
  font-size: 16px;
  text-transform: uppercase;
  width: 255px;
  text-align: left;
  line-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropUp:hover {
  cursor: pointer;
}

.dropUpGrey {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  text-align: center;
  color: #e46c0a;
  min-height: 35px;
  max-height: 35px;
  min-width: 235px;
  max-width: 235px;
  font-size: 16px;
  text-transform: uppercase;
  width: 255px;
  text-align: left;
  line-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #75869d;
}

.dropUpGrey:hover {
  cursor: pointer;
}

.dropUpAnswer {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  text-align: center;
  color: #e46c0a;
  min-height: 35px;
  max-height: 35px;
  font-size: 16px;
  max-width: 120px;
  min-width: 120px;
}

.dropUpContainer {
  background-color: #fff;
  position: absolute;
  bottom: 40px;
  left: 1rem;
  width: 55%;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.dropUpText {
  color: #3f2406;
  padding-left: 0.5rem;
  line-height: 18px;
  flex: 1;
}

.dropUpCheck {
  width: 16px;
  height: 17px;
  align-self: center;
  padding-right: 0.5rem;
}

.textHover {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.textHover :hover {
  background-color: #e46c0a3f;
  cursor: pointer;
}

.textHover:hover .dropUpCheck {
  background-color: #e46c0a3f;
}

.textHover:hover .dropUpText {
  background-color: #e46c0a3f;
}

.questionText {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #3f2406;
}

.yesNoButtonFilled {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  text-align: center;
  color: white;
  background-color: #e46c0a;
  min-height: 35px;
  max-height: 35px;
  max-width: 40px;
  min-width: 55px;
  font-size: 16px;
  margin-right: 15px;
  text-transform: uppercase;
}

.yesNoButtonNotFilled {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  text-align: center;
  color: #e46c0a;
  background-color: white;
  min-height: 35px;
  max-height: 35px;
  max-width: 55px;
  min-width: 55px;
  font-size: 16px;
  margin-right: 15px;
  text-transform: uppercase;
}

.yesNoButtonFilled:active,
.yesNoButtonNotFilled:active {
  transform: scale(0.9);
}

.yesNoButtonFilled:hover,
.yesNoButtonNotFilled:hover {
  cursor: pointer;
}

.multipleFilled {
  transition: 0.3s;
  border: 1px solid #e46c0a;
  border-radius: 5px;
  text-align: center;
  color: white;
  background-color: #e46c0a;
  font-size: 16px;
  min-height: 35px;
  max-height: 35px;
  max-width: 120px;
  min-width: 120px;
  margin-right: 15px;
  margin-top: 10px;
  text-transform: uppercase;
}

.multipleNotFilled {
  transition: 0.3s;
  border: 1px solid #e46c0a;
  border-radius: 5px;
  text-align: center;
  color: #e46c0a;
  background-color: white;
  min-height: 35px;
  max-height: 35px;
  font-size: 16px;
  max-width: 120px;
  min-width: 120px;
  margin-right: 15px;
  margin-top: 10px;
  text-transform: uppercase;
}

.multipleFilled:active,
.multipleNotFilled:active {
  transform: scale(0.9);
}

.multipleFilled:hover,
.multipleNotFilled:hover {
  cursor: pointer;
}

.contactUs {
  border: 1px solid #e46c0a;
  border-radius: 5px;
  background-color: #e46c0a;
  color: white;
  font-size: 14px;
  min-height: 35px;
  max-height: 35px;
  max-width: 140px;
  min-width: 140px;
  margin-right: 10px;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 300;
  padding: 0.5rem 1rem;
  transform: scale(0.95, 1.1);
}

.prevButton {
  border: 1px solid #e46c0a;
  background-color: white;
  border-radius: 5px;
  color: #e46c0a;
  font-size: 14px;
  min-height: 35px;
  max-height: 35px;
  max-width: 140px;
  min-width: 140px;
  margin-right: 10px;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 300;
  padding: 0.5rem 1rem;
  transform: scale(0.95, 1.1);
}

.prevButton:hover {
  cursor: pointer;
}

.prevButton:active {
  transform: scale(0.85, 0.9);
}

.prevButton:disabled {
  background-color: #ebebeb;
  color: white;
  border: 1px solid white;
}

.contactUs:hover {
  cursor: pointer;
  transition: 0.3s;
  background-color: white;
  color: #e46c0a;
}

.contactUs:active {
  transform: scale(0.85, 0.9);
}

.contactUs:disabled {
  background-color: #ebebeb;
  color: white;
  border: 1px solid white;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}

.m2 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #e46c0a;
  margin-left: 5px;
}

.contactButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.input:focus {
  outline: none;
  border: 1px solid #e46c0a;
}

@media screen and (min-width: 576px) {
  .modal {
    width: 800px;
    height: 400px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .modalSmall {
    width: 800px;
    height: 80px;
  }

  .formInput {
    width: 49%;
  }

  .questionText {
    margin-bottom: 10px;
  }

  .input {
    min-height: 40px;
    max-height: 40px;
    max-width: 120px;
    min-width: 120px;
  }

  .yesNoButtonFilled {
    transition: 0.3s;
    min-height: 55px;
    max-height: 55px;
    max-width: 55px;
    min-width: 55px;
    margin-right: 15px;
  }

  .yesNoButtonNotFilled {
    transition: 0.3s;
    min-height: 55px;
    max-height: 55px;
    max-width: 55px;
    min-width: 55px;
    margin-right: 15px;
  }

  .multipleFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 120px;
    min-width: 120px;
    margin-right: 15px;
  }

  .multipleNotFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 120px;
    min-width: 120px;
    margin-right: 15px;
  }

  .dropUp {
    min-height: 45px;
    max-height: 45px;
    min-width: 370px;
    max-width: 370px;
  }

  .dropUpGrey {
    min-height: 45px;
    max-height: 45px;
    min-width: 370px;
    max-width: 370px;
  }
}
@media screen and (min-width: 768px) {
  .questionText {
    margin-bottom: 10px;
  }

  .input {
    min-height: 40px;
    max-height: 40px;
    max-width: 120px;
    min-width: 120px;
  }

  .yesNoButtonFilled {
    min-height: 55px;
    max-height: 55px;
    min-width: 75px;
    margin-right: 15px;
  }

  .yesNoButtonNotFilled {
    min-height: 55px;
    max-height: 55px;
    min-width: 75px;
    margin-right: 15px;
  }

  .multipleFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 120px;
    min-width: 120px;
    margin-right: 15px;
    margin-top: 10px;
  }

  .multipleNotFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 120px;
    min-width: 120px;
    margin-right: 15px;
    margin-top: 10px;
  }

  .dropUp {
    min-width: 370px;
    max-width: 370px;
    min-height: 45px;
    max-height: 45px;
    line-height: 45px;
  }

  .dropUpGrey {
    min-width: 370px;
    max-width: 370px;
    min-height: 45px;
    max-height: 45px;
    line-height: 45px;
  }
}
@media screen and (min-width: 992px) {
  .newContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: calc(2 * (1.25rem + 85px));
  }

  .newContainerButton {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 0;
    margin-right: 20px;
  }

  .container {
    margin-top: -6rem;
  }

  .questionText {
    margin-bottom: 10px;
  }

  .input {
    min-height: 40px;
    max-height: 40px;
    max-width: 220px;
    min-width: 220px;
  }

  .yesNoButtonFilled {
    min-height: 45px;
    max-height: 45px;
    min-width: 103px;
    margin-right: 15px;
  }

  .yesNoButtonNotFilled {
    min-height: 45px;
    max-height: 45px;
    min-width: 103px;
    margin-right: 15px;
  }

  .multipleFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 220px;
    min-width: 220px;
    margin-right: 15px;
  }

  .multipleNotFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 220px;
    min-width: 220px;
    margin-right: 15px;
  }

  .dropUp {
    min-width: 670px;
    max-width: 670px;
    min-height: 45px;
    max-height: 45px;
    line-height: 45px;
  }

  .dropUpGrey {
    min-width: 670px;
    max-width: 670px;
    min-height: 45px;
    max-height: 45px;
    line-height: 45px;
  }

  .contactUs {
    min-height: 45px;
    max-height: 45px;
    max-width: 60px;
    min-width: 60px;
    margin-right: 15px;
  }

  .prevButton {
    min-height: 45px;
    max-height: 45px;
    max-width: 60px;
    min-width: 60px;
    margin-right: 15px;
  }
}
@media screen and (min-width: 1200px) {
  .questionText {
    margin-bottom: 10px;
  }

  .input {
    min-height: 40px;
    max-height: 40px;
    max-width: 220px;
    min-width: 220px;
  }

  .yesNoButtonFilled {
    min-height: 45px;
    max-height: 45px;
    min-width: 103px;
    margin-right: 15px;
  }

  .yesNoButtonNotFilled {
    min-height: 45px;
    max-height: 45px;
    min-width: 103px;
    margin-right: 15px;
  }

  .multipleFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 220px;
    min-width: 220px;
    margin-right: 15px;
  }

  .multipleNotFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 220px;
    min-width: 220px;
    margin-right: 15px;
  }

  .dropUp {
    min-width: 670px;
    max-width: 670px;
    min-height: 45px;
    max-height: 45px;
    line-height: 45px;
  }

  .dropUpGrey {
    min-width: 670px;
    max-width: 670px;
    min-height: 45px;
    max-height: 45px;
    line-height: 45px;
  }
}
@media screen and (min-width: 1400px) {
  .questionText {
    margin-bottom: 10px;
  }

  .input {
    min-height: 40px;
    max-height: 40px;
    max-width: 220px;
    min-width: 220px;
  }

  .yesNoButtonFilled {
    min-height: 45px;
    max-height: 45px;
    min-width: 103px;
    margin-right: 15px;
  }

  .yesNoButtonNotFilled {
    min-height: 45px;
    max-height: 45px;
    min-width: 103px;
    margin-right: 15px;
  }

  .multipleFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 220px;
    min-width: 220px;
    margin-right: 15px;
  }

  .multipleNotFilled {
    min-height: 45px;
    max-height: 45px;
    max-width: 220px;
    min-width: 220px;
    margin-right: 15px;
  }

  .dropUp {
    min-width: 670px;
    max-width: 670px;
    min-height: 45px;
    max-height: 45px;
    line-height: 45px;
  }

  .dropUpGrey {
    min-width: 670px;
    max-width: 670px;
    min-height: 45px;
    max-height: 45px;
    line-height: 45px;
  }
}
